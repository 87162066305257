import styled, { css } from 'styled-components';

import { Input, Container } from 'Components/Search/Search.styled';

import { ReactComponent as WaveSVG } from 'Public/graphics/waves/course-search/course-search-wave.svg';

export const Heading = styled.h2`
    ${({ theme }) => css`
        @media ${theme.breakpoints.large.media} {
            margin-top: 0;
        }
    `}
`;

export const Wrapper = styled.div`
    ${({ theme }) => css`
        padding: 1rem 0 2rem 0;
        position: relative;
        background-color: var(--coursesearch-bg-color, ${theme.colors.secondary.tints[1].hex});
        color: var(--coursesearch-txt-color, ${theme.colors.tertiary.hex});

        ${Input} {
            border: ${theme.commonStyles.brandPixel} solid black;
        }

        ${Container} {
            margin-bottom: 1rem;
        }

        @media ${theme.breakpoints.large.media} {
            padding: 3rem 0;
        }
    `}
`;

export const Background = styled.div`
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

export const Wave = styled(WaveSVG)`
    ${({ theme }) => css`
        position: absolute;
        pointer-events: none;
        transform: scale(1.2) rotate(354deg);
        width: 100%;
        top: 0rem;
        left: 0rem;

        @media ${theme.breakpoints.large.media} {
            left: -10rem;
            transform: scale(1.2) rotate(10deg);
        }

        @media ${theme.breakpoints.xlarge.media} {
            top: 0rem;
            left: -7rem;
        }
    `}
`;
