import React from 'react';

import Search, { CourseSearchProps } from 'Components/Search/Course';
import Link from 'Elements/Link';
import ArrowLink, { ArrowLinkProps } from 'Elements/Link/Arrow';
import Grid, { Col } from 'Components/Grid';

import { Wrapper, Background, Wave, Heading } from './CourseSearch.styled';

export interface Props {
    link?: ArrowLinkProps;
    promoHeading: string;
    text: string;
    search: CourseSearchProps;
    textColor?: string;
    backgroundColor?: string;
}

const CourseSearch: React.FC<Props> = ({
    search,
    promoHeading,
    text,
    textColor,
    backgroundColor,
    link
}) => {
    return (
        <Wrapper
            style={
                {
                    '--coursesearch-bg-color': backgroundColor,
                    '--coursesearch-txt-color': textColor
                } as React.CSSProperties
            }
        >
            <Background>
                <Wave />
            </Background>
            <Grid rowGap="1rem">
                <Col m={8} l={[2, 6]}>
                    <Heading>Course search</Heading>
                    <p>Find your perfect course</p>
                    <Search {...search} />
                    <Link
                        href="/courses"
                        ariaLabel="Click to access all courses"
                        label="View all courses"
                        bold
                    />
                </Col>
                <Col m={8} l={[7, 12]}>
                    <Heading>{promoHeading}</Heading>
                    <p>{text}</p>
                    {link && <ArrowLink {...link} />}
                </Col>
            </Grid>
        </Wrapper>
    );
};

export default CourseSearch;
