import styled, { css } from 'styled-components';

export const Container = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        border-radius: 0.5rem;
        width: 100%;
        overflow: hidden;
        z-index: 1;
        flex-direction: column;

        @media ${theme.breakpoints.medium.media} {
            min-height: 20rem;
        }

        @media ${theme.breakpoints.large.media} {
            flex-direction: row;
            min-height: 30rem;
        }
    `}
`;

export const Content = styled.div`
    ${({ theme }) => css`
        width: 100%;
        padding: 1rem 1.75rem 1.5rem;
        z-index: 2;
        background-color: ${theme.colors.tertiary.hex};
        position: relative;
        color: ${theme.colors.secondary.hex};
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media ${theme.breakpoints.medium.media} {
            padding: 1rem 1.5rem 2.75rem;
        }

        @media ${theme.breakpoints.large.media} {
            grid-row: 1;
            padding: 2.5rem 2.5rem 2.5rem 3rem;
            width: 50%;
        }
    `}
`;

const headingCSS = (theme, $color) => css`
    font-family: ${theme.fonts.secondary};
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
    color: ${$color || theme.colors.accents[3].hex};

    @media ${theme.breakpoints.large.media} {
        font-size: 2.5rem;
    }
`;

export const Heading = styled.h2<{ $color?: string }>`
    ${({ theme, $color }) => css`
        ${headingCSS(theme, $color)}
        margin: 0;
    `}
`;

export const InnerContent = styled.div<{ $color?: string }>`
    ${({ theme, $color }) => css`
        && {
            button,
            a {
                color: ${$color || theme.colors.accents[3].hex};

                &:hover {
                    color: ${$color || theme.colors.accents[3].hex};
                }
            }

            h2 {
                ${headingCSS(theme, $color)}
            }
        }
    `}
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        position: relative;
        width: 100%;
        grid-column: 1;
        grid-row: 1;
        z-index: -1;
        height: 15rem;

        @media ${theme.breakpoints.medium.media} {
            height: 20rem;
        }

        @media ${theme.breakpoints.large.media} {
            width: 50%;
            height: auto;
        }
    `}
`;
