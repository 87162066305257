import React from 'react';

import Grid, { Col } from 'Components/Grid';

import Inner, { WaveHeroInnerProps } from './subcomponents/Inner';

import {
    Container,
    OverflowWrapper,
    WaveWrapper,
    Wrapper,
    Wave,
    WaveGrey,
    WavePublications,
    WaveStories
} from './MakeWaves.styled';

export interface Props {
    inner: WaveHeroInnerProps;
    innerColWidth?: number;
    oneColumn?: boolean;
    backgroundColour?: string;
    textColour?: string;
    condensed?: boolean;
    children?: React.ReactNode;
}

const MakeWaves: React.FC<Props> = props => {
    const {
        inner,
        oneColumn,
        backgroundColour,
        textColour,
        condensed = false,
        children,
        innerColWidth = 6
    } = props;

    return (
        <Container
            $condensed={condensed}
            $overflow={
                backgroundColour === '#E8E9EA'
                    ? true
                    : backgroundColour === '#333333'
                    ? true
                    : false
            }
            style={
                {
                    '--hero-bg-colour': backgroundColour,
                    '--hero-txt-colour': textColour
                } as React.CSSProperties
            }
        >
            <OverflowWrapper>
                <WaveWrapper>
                    {backgroundColour === '#FFFFFF' ? (
                        <WaveGrey />
                    ) : backgroundColour === '#E8E9EA' ? (
                        <WavePublications />
                    ) : backgroundColour === '#333333' ? (
                        <WaveStories />
                    ) : (
                        <Wave />
                    )}
                </WaveWrapper>
            </OverflowWrapper>
            <Wrapper>
                <Grid layout="fill">
                    <Col m={oneColumn ? 12 : innerColWidth}>
                        <Inner {...inner} />
                    </Col>
                    {children}
                </Grid>
            </Wrapper>
        </Container>
    );
};

export default MakeWaves;
